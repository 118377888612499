@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

@import url("https://fonts.googleapis.com/css?family=Baloo+Bhaina");
.grid {
	.container {
		padding: 100px 0;
		display: flex;
		justify-content: center;

		h1 {
			font-size: 40px !important;
		}
		h2 {
			margin-bottom: 10px !important;
			font-style: italic;
		}
		h3 {
			margin-bottom: 5px;
			color: $neutral-3 !important;
			font-weight: bold;
		}
		.die {
			position: relative;
			padding-right: 50px;
			user-select: none;

			img {
				width: 250px;
				height: 250px;
				filter: drop-shadow(5px 5px 3px $black);
			}

			span {
				font-family: "Baloo Bhaina", cursive;
				text-shadow: 1px 1px #ffcfe3, -1px -1px #d42758;
				text-align: center;
				position: absolute;
				color: #ff517b;
				font-size: 30px;
				width: 250px;
				top: 105px;
				left: 0;
			}
		}
		.content {
			padding-top: 35px;
		}

		ul {
			list-style: none;
			padding: 0;
			margin: 0 0 20px 0;

			li {
				margin-bottom: 3px;
				a {
					color: $neutral-3;

					&:hover {
						color: $neutral-1;
					}
				}
			}
		}
	}
}
@media only screen and (max-width: 980px) {
	.grid {
		.container {
			padding-top: 50px;
			.die {
				display: none;
			}
		}
	}
}
